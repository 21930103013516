.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.imageContainer {
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s;
  pointer-events: none;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}

.showImage {
  opacity: 1;
  pointer-events: visible;
}

.indicatorContainer {
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;
  bottom: 13px;
}

.indicator {
  width: 5px;
  height: 5px;
  background-color: #fff;
  opacity: 0.5;
  transition: opacity 1s;
  cursor: pointer;
  & + .indicator {
    margin-left: 4px;
  }
}

.selectedIndicator {
  opacity: 1;
}
