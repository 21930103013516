.dimmed {
  position: fixed;
  z-index: 4010;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: auto;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  transform: translate3d(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
}

.container {
  position: relative;
  box-sizing: border-box;
  width: 304px;
  height: 421px;
  margin: auto;
  padding: 7px 5px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
}

.title {
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 5px;
  letter-spacing: -0.33px;
  color: #272627;
  &::before {
    position: absolute;
    left: 9px;
    display: inline-block;
    width: 40px;
    height: 10px;
    content: '';
    background: url(~public/static/images/main/popup/three_light.svg);
  }
}

.carouselContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 348px;
  margin-bottom: 14px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-left: 7px;
}

.todayNotShow {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #272627;
  span {
    width: 20px;
    height: 20px;
    border: solid 1px #c7c8cc;
  }
}

.labelText {
  margin-left: 10px;
}

.closeButton {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-right: 24px;
  color: #272627;

  &::before {
    display: inline-block;
    width: 1px;
    height: 18px;
    margin-right: 29px;
    content: '';
    background-color: #d8d8d8;
  }
}
